import { Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import ConfirmarDialog from '../../components/ConfirmarDialog/ConfirmarDialog'
import { CicloEtapaEnum } from '../../utils/enum'
import { CabecalhoTabelaContabilizacao } from './components/CabecalhoTabelaContabilizacao/index'
import { CicloEtapa } from './components/CicloEtapa'
import { CicloInformacao } from './components/CicloInformacao'
import { ErroProcessamento } from './components/ErroProcessamento/index'
import { LancamentoNaoAssociado } from './components/LancamentoNaoAssociado/index'
import { LancamentoVariaveis } from './components/LancamentoVariaveis'
import { ResumoConciliacao } from './components/ResumoConciliacao/index'
import { TabelaCiclo } from './components/TabelaCiclo/index'
import { TabelaContabilizacao } from './components/TabelaContabilizacao/index'
import { ValidacaoCiclo } from './components/ValidacaoCiclo/index'
import { VincularOPS } from './components/VincularOPS/index'
import { IniciarNovoCicloIO } from './IniciarNovoCiclo.IO'
import { ModalConfirmacaoBaixoValor } from './components/ModalConfirmacaoBaixoValor'

export type IniciarNovoCicloProps = {}

export function IniciarNovoCicloView({ _io }: IOProps<IniciarNovoCicloIO, IniciarNovoCicloProps>) {
  const { Container } = _io.styles
  return (
    <>
      <CicloInformacao
        cicloId={_io.cicloId}
        parceiroId={_io.statusAtualCiclo.parceiroId}
        enableConfirmar={_io.enableConfirmar}
        opsSucesso={_io.opsSucesso}
        updateStatus={_io.updateStatus}
        statusCiclo={_io.statusAtualCiclo}
        finalizarEtapa={_io.finalizarEtapa}
        atualizarEtapa={_io.atualizarEtapa}
        setEnableConfirmar={_io.setEnableConfirmar}
        disableCancelar={_io.disableCancelar}
      />
      <CicloEtapa statusCiclo={_io.statusAtualCiclo} />
      {_io.statusAtual === CicloEtapaEnum.transformacaoComErros && (
        <ErroProcessamento cicloId={_io.cicloId} parceiroId={_io.statusAtualCiclo.parceiroId} setHabilitarConfirmar={_io.setEnableConfirmar} />
      )}
      {_io.statusAtual === CicloEtapaEnum.associacoesComErros && (
        <LancamentoNaoAssociado
          cicloId={_io.cicloId}
          setHabilitarConfirmar={_io.setEnableConfirmar}
          cicloStatusGeral={_io.statusAtualCiclo}
          parceiroId={_io.statusAtualCiclo.parceiroId}
        />
      )}
      {_io.statusAtual === CicloEtapaEnum.itemsVariaveisErros && (
        <LancamentoVariaveis setEnableConfirmar={_io.setEnableConfirmar} parceiroId={_io.statusAtualCiclo.parceiroId} cicloId={_io.cicloId} />
      )}
      {_io.verificarStatusVinculacaoOPs() && (
        <VincularOPS cicloId={_io.cicloId} setEnableConfirmar={_io.setEnableConfirmar} setOpsSucesso={_io.setOpsSucesso} />
      )}
      {_io.statusAtual &&
      _io.statusAtual > CicloEtapaEnum.planilhaTransformando &&
      _io.statusAtual < CicloEtapaEnum.validacaoConciliacaoCiclo &&
      _io.statusAtual !== CicloEtapaEnum.cicloCancelamentoSucesso ? (
        <TabelaCiclo updateTable={_io.updateTable} cicloId={_io.cicloId} />
      ) : null}
      {_io.verificarStatusValidacao() && (
        <>
          <ValidacaoCiclo
            cicloId={_io.cicloId}
            conferirValidacaoCiclo={_io.conferirValidacaoCiclo}
            contabilizacaoValores={_io.contabilizacaoValores}
            setContabilizacaoValores={_io.setContabilizacaoValores}
            adjustValues={_io.ajustarValoresContabilizacao}
          />
          <Container>
            <CabecalhoTabelaContabilizacao
              buscarValores={_io.buscarValoresContabilizacao}
              searchEnter={_io.filtrarContabilizacoes}
              setBuscarValores={(event) => _io.setBuscarValoresContabilizacao(event.target.value)}
              abrirModal={_io.modalFiltroDadosContabilizacao}
              handleFecharModal={_io.handleFecharModal}
              contabilizacaoList={_io.dadosDetalhesFiltrados}
              gerarRelatorio={_io.gerarRelatorio}
              status={_io.gerarRelatorioContabilizacao.processamento?.status}
              url={_io.gerarRelatorioContabilizacao.url}
            />
            <TabelaContabilizacao
              cicloId={_io.cicloId}
              buscarContabilizacoes={_io.buscarContabilizacoes}
              totalRows={_io.totalLinhasTabelaContabilizacao}
              contabilizacaoList={_io.contabilizacaoLista}
            />
          </Container>
        </>
      )}
      {(_io.statusAtual === CicloEtapaEnum.conciliacaoFinanceiraSucesso || _io.statusAtual === CicloEtapaEnum.conciliacaoLoteEfetivado) && (
        <>
          <ResumoConciliacao cicloId={_io.cicloId} />
          <Container>
            <CabecalhoTabelaContabilizacao
              buscarValores={_io.buscarValoresContabilizacao}
              searchEnter={_io.filtrarContabilizacoes}
              setBuscarValores={(event) => _io.setBuscarValoresContabilizacao(event.target.value)}
              abrirModal={_io.modalFiltroDadosContabilizacao}
              handleFecharModal={_io.handleFecharModal}
              contabilizacaoList={_io.dadosDetalhesFiltrados}
              gerarRelatorio={_io.gerarRelatorio}
              url={_io.gerarRelatorioContabilizacao.url}
              status={_io.gerarRelatorioContabilizacao.processamento?.status}
            />
            <TabelaContabilizacao
              cicloId={_io.cicloId}
              buscarContabilizacoes={_io.buscarContabilizacoes}
              totalRows={_io.totalLinhasTabelaContabilizacao}
              contabilizacaoList={_io.contabilizacaoLista}
            />
          </Container>
        </>
      )}
      {_io.modalConfirmacaoBaixoValor ? (
        <ModalConfirmacaoBaixoValor
          abrirModal={_io.modalConfirmacaoBaixoValor}
          fecharModal={() => _io.setModalConfirmacaoBaixoValor(false)}
          submeterFrase={_io.submeterFrase}
          tituloModal={'Validação de ciclo'}
          textoModal={'O valor total dos títulos liquidados é inferior ao limite mínimo permitido. Para confirmar, digite "Estou de acordo" no campo abaixo.'}
        />
      ) : _io.modalCancelarNovoCiclo ? (
        <ConfirmarDialog
          open={_io.modalCancelarNovoCiclo}
          setOpen={_io.setModalCancelarNovoCiclo}
          handleConfirm={_io.concluirValidacaoCiclo}
          confirmarButtonLabel="Sim, tenho!"
          cancelarButtonLabel="Não"
        >
          <Typography variant="body1">
            Tem certeza que deseja efetivar o ciclo <strong>{_io.cicloId}</strong>
            para a conciliação financeira?
          </Typography>
        </ConfirmarDialog>
      ) : null}
    </>
  )
}
