import instance from '../../axiosInstance'

const recebeUrlValidaDownload = async (filePath: string): Promise<string> => {
  const URL_RECEBE_URL_DOWNLOAD = `/Arquivo?filePath=${encodeURIComponent(filePath)}`
  const response = await instance.conectarGet<string>(URL_RECEBE_URL_DOWNLOAD)
  return response.data
}

export default {
  recebeUrlValidaDownload
}
