import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { TIPO_PARAMETRO } from '../../../../constants/utilConstants'
import { useConciliacaoContext } from '../../../../contexts/conciliacao-context'
import { PAGES, ABAS_DETALHES_PARCEIROS } from '../../../../router/routes'
import TransformacaoManager from '../../../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { Acao, Condicao } from '../../../../types/regraApiType'
import { GrupoRegra, GrupoRegraSelect, PreviewItemTransformacao, RegraTransformacao, RegraTransformacaoForm } from '../../../../types/transformacaoApiTypes'
import { converterToAcaoTransformacaoForm, converterToCondicaoTransformacaoForm, converterToGrupoRegraSelect } from '../../../../utils/regraFormsUtils'
import { estaVazio } from '../../../../utils/stringUtils'
import { RegraTransformacaoFormProps } from './RegraTransformacaoForm.View'

const dadosIniciaisFormulario: RegraTransformacaoForm = {
  nomeRegra: '',
  campo: 'TipoLancamento',
  condicoes: [],
  acoes: [],
  grupos: []
}

export function regraTransformacaoFormIO({ regraTransformacaoEditar }: RegraTransformacaoFormProps) {
  const router = useHistory()
  const dispatch = useDispatch()
  const { parceiroId, regraId } = useParams<{ parceiroId: string; regraId: string }>()
  const { headersData, getHeadersPlanilha } = useConciliacaoContext()
  const [arquivoId, setArquivoId] = useState('')
  const [acaoList, setAcaoList] = useState<Acao[]>([])
  const [grupoRegraList, setGrupoRegraList] = useState<GrupoRegra[]>([])
  const [condicaoList, setCondicaoList] = useState<Condicao[]>([])
  const [preVisualizar, setpreVisualizar] = useState<PreviewItemTransformacao>()
  const [openModal, setOpenModal] = useState(false)
  const [save, setSave] = useState(false)
  const [switchAtivo, setSwitchAtivo] = useState(regraTransformacaoEditar?.ativo)

  const retornarValoresUnicos = (arrayAvaliado: string[]) => {
    return arrayAvaliado.filter((valor, index, valorComparado) => valorComparado.indexOf(valor) === index)
  }

  const popularDropDownDados = () => {
    if (!regraTransformacaoEditar) {
      return []
    }

    let headersArray: string[] = []

    headersArray = regraTransformacaoCondicao(regraTransformacaoEditar, headersArray)
    headersArray = regrasTransformacaoAcao(regraTransformacaoEditar, headersArray)

    return retornarValoresUnicos(headersArray)
  }

  const [fieldDado, setFieldDado] = useState(popularDropDownDados())

  const parametrosForm = useForm<RegraTransformacaoForm>({
    defaultValues: regraTransformacaoEditar
      ? {
          ...regraTransformacaoEditar,
          acoes: converterToAcaoTransformacaoForm(regraTransformacaoEditar.acoes),
          condicoes: converterToCondicaoTransformacaoForm(regraTransformacaoEditar.condicoes),
          grupos: converterToGrupoRegraSelect(regraTransformacaoEditar.grupos)
        }
      : dadosIniciaisFormulario
  })

  useEffect(() => {
    async function buscarCondicoes() {
      const response: Condicao[] = await TransformacaoManager.buscarCondicoes()
      setCondicaoList(response)
    }

    async function buscarAcoes() {
      const response: Acao[] = await TransformacaoManager.buscarAcoes()
      setAcaoList(response)
    }

    async function buscarGrupoRegra() {
      const response: GrupoRegra[] = await TransformacaoManager.buscarGruposRegras(parceiroId)
      setGrupoRegraList(response)
      dispatch(loadingReset())
    }

    dispatch(loadingUpdate(true))
    buscarCondicoes()
    buscarAcoes()
    buscarGrupoRegra()
  }, [dispatch, parceiroId])

  useEffect(() => {
    if (headersData && headersData.length != 0) setFieldDado(headersData)
  }, [headersData])

  const handlePreview = async () => {
    const regraForm: RegraTransformacaoForm = parametrosForm.getValues()
    regraForm.grupos = regraForm.grupos.map((item: GrupoRegraSelect) => item.value)

    if (arquivoId) {
      dispatch(loadingUpdate(true))

      await TransformacaoManager.previewRegra(regraForm, arquivoId)
        .then((response: PreviewItemTransformacao) => {
          setpreVisualizar(response)
          setOpenModal(true)
        })
        .catch(() => {
          toast.error(msgConstants.ERRO_PRE_VISUALIZACAO)
        })
        .finally(() => {
          dispatch(loadingReset())
        })
    } else {
      toast.error(msgConstants.ARQUIVO.uploadPreVisualizarErro)
    }
  }

  const handleCancelar = () => {
    getHeadersPlanilha([])
    router.push(PAGES.parametrosRepasse(parceiroId, ABAS_DETALHES_PARCEIROS.transformacao))
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const mudarStatusParaAtivo = (event: any) => {
    setSwitchAtivo(event.target.checked)
  }

  const salvarDados = async (data: RegraTransformacaoForm) => {
    async function cadastrarRegra() {
      data.grupos = data.grupos.map((item: GrupoRegraSelect) => item.value)
      await TransformacaoManager.cadastrarRegra(data, parceiroId)
        .then(() => {
          toast.success(msgConstants.USO_GENERICO.cadastro_sucesso)
          dispatch(loadingReset())
          save ? parametrosForm.reset() : router.push(PAGES.parametrosRepasse(parceiroId, ABAS_DETALHES_PARCEIROS.transformacao))
        })
        .catch(() => dispatch(loadingReset()))
    }
    dispatch(loadingUpdate(true))

    cadastrarRegra()
    !save && getHeadersPlanilha([])
  }

  const editarDados = (data: RegraTransformacaoForm) => {
    async function editarRegra() {
      data.ativo = switchAtivo
      data.grupos = data.grupos.map((item: GrupoRegraSelect) => item.value)

      await TransformacaoManager.editarRegra(data, parceiroId, regraId)
        .then(() => {
          toast.success(msgConstants.USO_GENERICO.edicao_sucesso)
          dispatch(loadingReset())
          router.push(PAGES.parametrosRepasse(parceiroId, ABAS_DETALHES_PARCEIROS.transformacao))
        })
        .catch(() => {
          dispatch(loadingReset())
          toast.error(msgConstants.USO_GENERICO.edicao_erro)
        })
    }
    dispatch(loadingUpdate(true))

    editarRegra()
    getHeadersPlanilha([])
  }

  const enviarDadosFormulario = (data: RegraTransformacaoForm) => {
    return handleRegraTransformacao(editarDados, data, salvarDados, regraId)
  }

  return {
    setArquivoId,
    parametrosForm,
    enviarDadosFormulario,
    mudarStatusParaAtivo,
    switchAtivo,
    condicaoList,
    acaoList,
    grupoRegraList,
    fieldDado,
    handlePreview,
    handleCancelar,
    setSave,
    openModal,
    handleCloseModal,
    preVisualizar,
    regraId
  }
}

export type RegraTransformacaoFormIO = ReturnType<typeof regraTransformacaoFormIO>

function handleRegraTransformacao(
  editarDados: (data: RegraTransformacaoForm) => void,
  data: RegraTransformacaoForm,
  salvarDados: (data: RegraTransformacaoForm) => Promise<void>,
  regraId?: string
) {
  return regraId ? editarDados(data) : salvarDados(data)
}

function regraTransformacaoCondicao(regraTransformacao: RegraTransformacao, arrayCondicao: string[]) {
  regraTransformacao.condicoes.forEach((condicao) => {
    arrayCondicao.push(condicao.dado)
    const parametroPreenchido = !estaVazio(condicao.parametro.parametro)
    const parametroIgualColuna = condicao.parametro.tipoParametro.tipoParametroId === TIPO_PARAMETRO.coluna
    if (parametroPreenchido && parametroIgualColuna) {
      arrayCondicao.push(condicao.parametro.parametro)
    }
  })
  return arrayCondicao
}

function regrasTransformacaoAcao(regraTransformacao: RegraTransformacao, arrayAcao: string[]) {
  regraTransformacao.acoes.forEach((acao) => {
    const dadosAcaoPreenchida = !estaVazio(acao.dado)
    const acaoPreenchida = !estaVazio(acao.parametro.parametro)
    const parametroIgualColuna = acao.parametro.tipoParametro.tipoParametroId === TIPO_PARAMETRO.coluna
    if (dadosAcaoPreenchida) arrayAcao.push(acao.dado)
    if (parametroIgualColuna && acaoPreenchida) {
      arrayAcao.push(acao.parametro.parametro)
    }
  })
  return arrayAcao
}
