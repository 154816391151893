import { useEffect, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { TIPO_PARAMETRO } from '../../../../constants/utilConstants'
import AssociacaoManager from '../../../../services/api/associacao/associacaoManager'
import ciclosManager from '../../../../services/api/ciclos/ciclosManager'
import tipoLancamentoManager from '../../../../services/api/tipoLancamento/tipoLancamentoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { PreviewItemAssociacao, RegraAssociacaoForm } from '../../../../types/associacaoApiTypes'
import { Condicao } from '../../../../types/regraApiType'
import { TiposLancamentosData } from '../../../../types/tipoLancamentoApiTypes'
import { gerarNomeRegraAssociacao } from '../../../../utils/stringUtils'
import { novaAssociacaoStyles } from './NovaAssociacao.style'
import { NovaAssociacaoProps } from './NovaAssociacao.View'

const defaultValues = {
  nomeRegra: '',
  campo: 'TipoLancamento',
  ativo: true,
  condicoes: [
    {
      operacaoId: '',
      dado: '',
      operador: '',
      parametro: {
        parametro: '',
        tipoParametro: TIPO_PARAMETRO.criterio
      },
      tipoCondicao: 'E'
    }
  ],
  acoes: [
    {
      operacaoId: 'TipoLancamentoOperacao',
      dado: '',
      operador: '',
      parametro: {
        parametro: '',
        tipoParametro: TIPO_PARAMETRO.criterio
      }
    }
  ]
}

const dadoField = ['Valor', 'TipoLancamento', 'Loja', 'Lancamento', 'IdentificadorPedido', 'Parcela']

export function novaAssociacaoIO({ tipoLancamento }: NovaAssociacaoProps) {
  const dispatch = useDispatch()
  const historico = useHistory<number | string | undefined | any>()
  const [condicaoList, setCondicaoList] = useState<Condicao[]>([])
  const [tipoLancamentoList, setTipoLancamentoList] = useState<TiposLancamentosData[]>([])
  const [abrirModal, setAbrirModal] = useState(false)
  const [preVisualizar, setpreVisualizar] = useState<PreviewItemAssociacao>()

  useEffect(() => {
    async function buscarCondicoes() {
      const response: Condicao[] = await AssociacaoManager.buscarCondicoes()
      setCondicaoList(response)
    }
    buscarCondicoes()
  }, [])

  useEffect(() => {
    async function fetchData() {
      const response: TiposLancamentosData[] = await tipoLancamentoManager.buscarTiposLancamentos()
      setTipoLancamentoList(response)
    }
    fetchData()
  }, [])

  const { handleSubmit, control, getValues } = useForm<RegraAssociacaoForm>({
    defaultValues
  })

  const condicoes = useFieldArray({
    name: 'condicoes',
    control
  })

  const acoes = useFieldArray({
    name: 'acoes',
    control
  })

  const condicaoSelecionada = useWatch({
    name: 'condicoes',
    control
  })

  const submeterAssociacao = (data: RegraAssociacaoForm) => {
    const { parceiro } = historico.location.state
    const nomeRegra = gerarNomeRegraAssociacao(data, tipoLancamentoList)
    data = { ...data, nomeRegra: nomeRegra }
    dispatch(loadingUpdate(true))
    AssociacaoManager.cadastrarRegra(data, parceiro)
      .then(() => {
        tratarLancamento()
        toast.success(msgConstants.USO_GENERICO.cadastro_sucesso)
      })
      .catch(() => {
        toast.error(msgConstants.LANCAMENTO_ASSOCIACAO.erroCadastro)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const handleFecharModal = () => {
    setAbrirModal(false)
  }

  const handlePreVisualize = async () => {
    const { parceiro } = historico.location.state
    const regraForm: RegraAssociacaoForm = getValues()
    dispatch(loadingUpdate(true))

    await AssociacaoManager.previewRegra(regraForm, parceiro)
      .then((response: PreviewItemAssociacao) => {
        setpreVisualizar(response)
        setAbrirModal(true)
      })
      .catch(() => {
        toast.error(msgConstants.ERRO_PRE_VISUALIZACAO)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  async function tratarLancamento() {
    const { idCiclo, linha } = historico.location.state
    const tipoTratamento = 'C'
    dispatch(loadingUpdate(true))
    await ciclosManager
      .tratarLancamentoNaoAssociado(idCiclo, linha, tipoTratamento)
      .then(() => {
        historico.goBack()
      })
      .catch(() => {
        toast.error(msgConstants.LANCAMENTO_ASSOCIACAO.erroLancamentoNaoAssociado)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  return {
    styles: novaAssociacaoStyles,
    handleSubmit,
    submeterAssociacao,
    control,
    condicoes,
    condicaoList,
    dadoField,
    condicaoSelecionada,
    tipoLancamento,
    acoes,
    handlePreVisualize,
    tratarLancamento,
    retornarPaginaAnterior: () => historico.goBack(),
    abrirModal,
    handleFecharModal,
    preVisualizar,
    tipoLancamentoList,
    adicionarMaisCondicoes: () =>
      condicoes.append({
        operacaoId: '',
        dado: '',
        operador: '',
        parametro: {
          parametro: '',
          tipoParametro: TIPO_PARAMETRO.criterio
        },
        tipoCondicao: 'E'
      })
  }
}
export type NovaAssociacaoIO = ReturnType<typeof novaAssociacaoIO>
