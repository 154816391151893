import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { detalhesOpsEmAbertoStyles } from './DetalhesOpsEmAberto.style'
import { useCallback, useEffect, useState } from 'react'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { ResponsePaginado } from '../../types/genericoType'
import { toast } from 'react-toastify'
import { Paginacao } from '../../types/tabelaTypes'
import msgConstants from '../../constants/msgConstants'
import { Parceiro } from '../../types/parceiroApiTypes'
import parceiroManager from '../../services/api/parceiro/parceiroManager'
import { GerarRelatorioOPsEmAbertoResponse, ObterOPsEmAbertoQuery, OpsEmAberto } from '../../types/regularizacaoOpsTypes'
import regularizacaoOPsManager from '../../services/api/regularizacaoOPs/regularizacaoOPsManager'
import { handleExportarArquivo } from '../../utils/uploadFileUtils'

export function detalhesOpsEmAbertoIO() {
  const dispatch = useDispatch()
  const router = useHistory()
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [detalhesOpsEmAbertoList, setDetalhesOpsEmAbertoList] = useState<ResponsePaginado<OpsEmAberto>>()
  const [parceiros, setParceiros] = useState<Parceiro[]>([])

  useEffect(() => {
    obterParceirosAtivos()
    obterOpsEmAberto({ pageNumber: 1, pageSize: 10 })
  }, [])

  const obterParceirosAtivos = useCallback(async () => {
    await parceiroManager
      .buscarDadosParceiros(true)
      .then((response: Parceiro[]) => {
        setParceiros(response)
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.erroBusca)
      })
  }, [])

  const schema = yup.object({
    dataFimEmissao: yup.string().test('valorMinimo', `Deve ser maior ou igual que 'Data de emissão'`, function (dataFimEmissao, form) {
      const dataInicial = '' + form.parent.dataInicioEmissao
      const dataFinal = '' + dataFimEmissao

      if (form.parent.dataInicioEmissao == '' || dataFimEmissao == '') return true

      return new Date(dataInicial) <= new Date(dataFinal)
    })
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    getValues
  } = useForm<ObterOPsEmAbertoQuery>({
    defaultValues: {
      parceiroId: 0,
      dataInicioEmissao: '',
      dataFimEmissao: ''
    },
    resolver: yupResolver(schema)
  })

  const obterOpsEmAberto = useCallback(
    async (paginacao: Paginacao) => {
      dispatch(loadingUpdate(true))

      await regularizacaoOPsManager
        .buscarOPsEmAberto(
          {
            pageNumber: paginacao.pageNumber,
            pageSize: paginacao.pageSize,
            orderBy: paginacao.orderBy,
            orderField: paginacao.orderField
          },
          {
            parceiroId: getValues('parceiroId'),
            dataInicioEmissao: getValues('dataInicioEmissao'),
            dataFimEmissao: getValues('dataFimEmissao')
          }
        )
        .then((detalhesOpsEmAbertoResponse: ResponsePaginado<OpsEmAberto>) => {
          setDetalhesOpsEmAbertoList(detalhesOpsEmAbertoResponse)
        })
        .catch((error: Error) => toast.error(error.message))
        .finally(() => dispatch(loadingReset()))
    },
    [dispatch, parceiroId]
  )

  const gerarRelatorioOpsEmAberto = useCallback(async () => {
    dispatch(loadingUpdate(true))

    await regularizacaoOPsManager
      .gerarRelatorioOPsEmAberto({
        parceiroId: getValues('parceiroId'),
        dataInicioEmissao: getValues('dataInicioEmissao'),
        dataFimEmissao: getValues('dataFimEmissao')
      })
      .then(async (urlRelatorioOpsEmAberto: GerarRelatorioOPsEmAbertoResponse) => {
        await handleExportarArquivo(urlRelatorioOpsEmAberto.url)
      })
      .catch((error: Error) => toast.error(error.message))
      .finally(() => dispatch(loadingReset()))
  }, [dispatch, parceiroId])

  const handleLimpar = () => {
    setValue('parceiroId', 0)
    setValue('dataInicioEmissao', '')
    setValue('dataFimEmissao', '')
    obterOpsEmAberto({ pageNumber: 1, pageSize: 10 })
  }

  const handleAplicar = () => {
    obterOpsEmAberto({ pageNumber: 1, pageSize: 10 })
  }

  return {
    styles: detalhesOpsEmAbertoStyles,
    router,
    handleSubmit,
    control,
    errors,
    register,
    handleLimpar,
    handleAplicar,
    detalhesOpsEmAbertoList,
    obterOpsEmAberto,
    parceiros,
    gerarRelatorioOpsEmAberto
  }
}

export type DetalhesOpsEmAbertoIO = ReturnType<typeof detalhesOpsEmAbertoIO>
