import { Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import AndamentoIcone from '../../assets/icons/andamento-icon.svg'
import ConcluidoIcone from '../../assets/icons/concluido-icon.svg'
import PendenteIcone from '../../assets/icons/pendente-icon.svg'
import { Tabela } from '../../components/Tabela'
import { customTheme } from '../../styles/customTheme'
import { ControleComissao, ControleComissaoStatus } from '../../types/controleComissaoTypes'
import { cabecalhoControleComissao } from '../../types/tabelaTypes'
import { CardComissao } from './components/CardComissao'
import { ControleComissaoIO } from './ControleComissao.IO'
import { ItemControleComissao } from './components/ItemControleComissao'
import { PopoverFiltroControleComissao } from './components/PopoverFiltroControleComissao'
import { Tipografia } from './../../components/Tipografia/index'
import { ContainerSombra } from '../../components/ContainerSombra'
import { ExportIcon } from '../../assets/icons/ExportIcon'
import { BotaoIconeSvg } from '../../components/BotaoIconeSvg'
import { handleExportarArquivo } from '../../utils/uploadFileUtils'

type ControleComissaoProps = {}

export function ControleComissaoView({ _io }: IOProps<ControleComissaoIO, ControleComissaoProps>) {
  const { Container, ContainerLinha } = _io.styles

  function linhaTabela(props: { item: ControleComissao }) {
    const { item } = props

    const historicoReverse = [...item.listaStatus].reverse()
    const itemAlterado = { ...item, listaStatus: historicoReverse }

    return <ItemControleComissao item={itemAlterado} atualizarLista={_io.atualizarLista} />
  }

  return (
    <>
      <Tipografia variant="h5">Controle de comissão</Tipografia>
      <Grid container spacing={2}>
        <CardComissao
          backgroundColor={customTheme.colors.vermelho01}
          icon={PendenteIcone}
          quantidade={_io.getQuantidadeIncidentes(ControleComissaoStatus.Pendente)}
          descricao={'pendentes'}
        />
        <CardComissao
          backgroundColor={customTheme.colors.amarelo01}
          icon={AndamentoIcone}
          quantidade={_io.getQuantidadeIncidentes(ControleComissaoStatus.EmAndamento)}
          descricao={'em andamento'}
        />
        <CardComissao
          backgroundColor={customTheme.colors.verde01}
          icon={ConcluidoIcone}
          quantidade={_io.getQuantidadeIncidentes(ControleComissaoStatus.Concluido)}
          descricao={'concluídos'}
        />
      </Grid>
      <ContainerSombra>
        <Container>
          <Typography variant="h6">Incidentes</Typography>
          <ContainerLinha>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Buscar"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={_io.handleSubmit(_io.buscarValorPesquisado)} edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
              name="pesquisa"
              control={_io.control}
            />
            <PopoverFiltroControleComissao
              parceirosAtivos={_io.parceirosAtivos}
              handleAplicarFiltro={_io.handleAplicarFiltro}
              setFiltroParceirosLista={_io.setFiltroParceirosLista}
              setFiltroStatusLista={_io.setFiltroStatusLista}
              filtroParceirosLista={_io.filtroParceirosLista}
              filtroStatusLista={_io.filtroStatusLista}
            />

            <BotaoIconeSvg
              onClick={async () => await handleExportarArquivo(process.env.REACT_APP_PLANILHA_COMISSOES ?? '')}
              icone={<ExportIcon width={24} height={24} color={customTheme.colors.azul05} />}
            />
          </ContainerLinha>
        </Container>
        <Tabela
          cabecalho={cabecalhoControleComissao}
          itensLista={_io.controleComissaoResumosPaginado.itens}
          LinhaTabela={linhaTabela}
          orderCampoPadrao={''}
          rowsPerPageOptions={[10, 25, 50]}
          linhasPorPaginaPadrao={25}
          maxHeight={'none'}
          alinharTextoCentro={false}
          buscarItensTabela={_io.buscarControleComissao}
          totalItens={_io.controleComissaoResumosPaginado.totalItens}
        />
      </ContainerSombra>
    </>
  )
}
