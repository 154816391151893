import { Base64 } from 'js-base64'
import Papa from 'papaparse'
import html2canvas from '../adapted_modules/modulo_html2canvas/html2canvas'
import { jsPDF } from 'jspdf'
import arquivoManager from '../services/api/arquivo/arquivoManager'
import { toast } from 'react-toastify'

type validExt =
  | {
      name: string
      value: string
    }
  | null
  | undefined

const papaparseOptions = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
  encoding: 'ISO-8859-1'
}

export const getExtensoesValidas = () => {
  return [
    {
      name: 'CSV',
      value: 'text/csv'
    },
    {
      name: 'XLS',
      value: 'application/excel'
    },
    {
      name: 'XLSX',
      value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  ]
}

export const getExtensaoArquivo = (name: string): validExt => {
  const extension = name.split('.').pop()

  return extension ? getExtensoesValidas().find((item) => item.name == extension.toUpperCase()) : undefined
}

export const b64DecodeUnicode = (str: string): string => {
  return decodeURIComponent(
    Array.prototype.map
      .call(Base64.atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

export const changeDuplicateColumnsName = (columnsNames: any) => {
  if (columnsNames == undefined) return

  columnsNames.forEach((columnName: string) => {
    const matches = columnsNames.filter((filterName: string) => filterName === columnName)

    if (matches.length > 1) {
      let suffixCounter = 0
      let index = 0

      while (suffixCounter < matches.length) {
        if (columnName === columnsNames[index]) {
          const suffix = '_' + (suffixCounter + 1)
          columnsNames[index] += suffix
          suffixCounter++
        }
        index++
      }
    }
  })
}

export const base64FileToCsvArray = (base64File: string): any[] => {
  const base64FileNoHeader = base64File.split(',')[1]
  let base64Decoded = ''

  try {
    base64Decoded = b64DecodeUnicode(base64FileNoHeader)
  } catch (e) {
    base64Decoded = Base64.atob(base64FileNoHeader)
  }

  const contentCsv = Papa.parse(base64Decoded, papaparseOptions)

  changeDuplicateColumnsName(contentCsv.data[0])

  return contentCsv.data
}

export const csvArrayToFile = (fileArray: any[], fileName: string, contentType: string) => {
  const csvString = arrayToCSV(fileArray)
  const csvFile = new Blob(['\ufeff' + csvString], { type: contentType })

  return new File([csvFile], fileName, { type: contentType })
}

export const arrayToCSV = (array: any[], delimiter = ';') =>
  array.map((lines: any) => lines.map((itens: any) => (typeof itens == 'string' ? '"' + itens.replaceAll('"', '') + '"' : itens)).join(delimiter)).join('\n')

export const exportPDF = (componenteId: string, dataPeriodo?: string, dataComparacao?: string) => {
  const componente = document.getElementById(componenteId)

  if (componente) {
    html2canvas(componente, {
      allowTaint: false,
      useCORS: true,
      onclone: function (document: Document, componenteClone: HTMLElement) {
        componenteClone.style.width = '1300px'
        retirarResponsividade(componenteClone, componenteId, document)
      }
    }).then((canvas: HTMLCanvasElement) => {
      const imagem = canvas.toDataURL('image/png', 1.0)
      let orientacao: 'portrait' | 'landscape' = 'portrait'

      if (canvas.width > canvas.height) orientacao = 'landscape'

      const pdf = new jsPDF(orientacao, 'pt', [canvas.width, canvas.height])
      pdf.addImage(imagem, 'JPEG', 0, 0, canvas.width, canvas.height)

      let nomeArquivo = componenteId + '_' + (dataPeriodo ? dataPeriodo : '')
      nomeArquivo += '_' + (dataComparacao ? dataComparacao : '')

      pdf.save(nomeArquivo + '.pdf')
    })
  }
}

function retirarResponsividade(componenteClone: HTMLElement, componenteId: string, document: Document) {
  const elementosResponsivos = componenteClone.getElementsByClassName('MuiGrid-item')

  //retirando responsividade do componente que será transformado em PDF
  for (const elemento of Array.from(elementosResponsivos)) {
    elemento.classList.remove('MuiGrid-grid-xs-12')
    elemento.classList.remove('MuiGrid-grid-sm-12')
    elemento.classList.remove('MuiGrid-grid-md-12')
  }

  if (componenteId == 'titulosGerados') {
    document.getElementById('card-valor-quantidade-gerados')?.classList.add('MuiGrid-grid-sm-4', 'MuiGrid-grid-xs-4')
    document.getElementById('grafico-dias-gerados')?.classList.add('MuiGrid-grid-sm-8', 'MuiGrid-grid-xs-8')
  }

  //retirar box-shadow
  const divs = document.querySelectorAll('div')
  for (const div of Array.from(divs)) {
    div.style.boxShadow = 'none'
  }
}

export const handleExportarArquivo = async (url: string) => {
  let newUrl = url
  if (url.includes('relatorios/')) {
    const filePath = 'relatorios/' + url.split('relatorios/')[1]
    try {
      newUrl = await arquivoManager.recebeUrlValidaDownload(filePath)
    } catch (error) {
      toast.error(`Failed to get pre-signed URL: ${error}`)
    }
  }
  window.open(newUrl, '_blank')
}
