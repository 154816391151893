import {
  arquivoTemp,
  Ciclos,
  CicloStatusGeral,
  CicloTabela,
  ErrosProcessamento,
  IFiltroOps,
  IniciarNovoCicloForm,
  IOpsVinculadas,
  ItemLancamentoNaoAssociado,
  LancamentosVariaveisApi,
  novoCiclo,
  ParceiroCiclo,
  TotalCiclo,
  TransicionarCiclo
} from '../../../types/ciclosApiType'
import { ResponsePaginado } from '../../../types/genericoType'
import { Order, Paginacao } from '../../../types/tabelaTypes'
import ciclosService from './ciclosService'
import { CicloPorParceiroForm } from './../../../types/ciclosApiType'

const buscarParceirosComCiclos = async (): Promise<ParceiroCiclo[]> => ciclosService.buscarCiclos()

const iniciarCiclo = async (parceiroId: string | number, body: IniciarNovoCicloForm): Promise<novoCiclo> => ciclosService.iniciarCiclo(parceiroId, body)

const deletarCiclo = async (cicloId?: string | number): Promise<void> => ciclosService.deletarCiclo(cicloId)

const buscarCicloParceiro = async (parceiroId: string | number, paginacao: CicloPorParceiroForm): Promise<ResponsePaginado<Ciclos>> =>
  ciclosService.buscarCicloParceiro(parceiroId, paginacao)

const buscarTabelaCiclo = async (paginacao: Paginacao, cicloId?: string | number, search?: string): Promise<CicloTabela> =>
  ciclosService.buscarTabelaCiclo(paginacao, cicloId, search)

const buscarErrosProcessamento = async (cicloId: string, pageNumber: string | number, pageSize: string | number): Promise<ErrosProcessamento> =>
  ciclosService.buscarErrosProcessamento(cicloId, pageNumber, pageSize)

const buscarLancamentosVariaveis = async (
  cicloId: string,
  numeroPagina: string | number,
  tamanhoPagina: string | number,
  ordernarPor?: Order,
  filtro?: string[],
  ordemCampo?: string
): Promise<LancamentosVariaveisApi> => ciclosService.buscarLancamentosVariaveis(cicloId, numeroPagina, tamanhoPagina, ordernarPor, filtro, ordemCampo)

const verificarItensVariaveis = async (cicloId: string): Promise<boolean> => ciclosService.verificarItensVariaveis(cicloId)

const editarItemLancamentoVariaveis = async (cicloId: string, objectApi: ItemLancamentoNaoAssociado[]): Promise<ItemLancamentoNaoAssociado[]> =>
  ciclosService.editarItemLancamentoVariaveis(cicloId, objectApi)

const buscarStatusCiclo = async (cicloId: string | number): Promise<CicloStatusGeral> => ciclosService.buscarStatusCiclo(cicloId)

const buscarLancamentoNaoAssociado = async (
  cicloId: string | number,
  pageNumber: string | number,
  pageSize: string | number
): Promise<LancamentosVariaveisApi> => ciclosService.buscarLancamentoNaoAssociadoService(cicloId, pageNumber, pageSize)

const tratarLancamentoNaoAssociado = async (cicloId: string | number, numeroLinha: number, tipoTratamento: string): Promise<LancamentosVariaveisApi> =>
  ciclosService.tratarLancamentoNaoAssociadoService(cicloId, numeroLinha, tipoTratamento)

const uploadArquivoCiclo = async (url: string, file: any, type: string): Promise<string> => ciclosService.uploadArquivoCiclo(url, file, type)

const uploadArquivoTemp = async (fileType: string, cicloId?: string | number): Promise<arquivoTemp> => ciclosService.uploadArquivoTemp(fileType, cicloId)

const concluirInternalizacao = async (cicloId?: string | number): Promise<string> => ciclosService.concluirInternalizacao(cicloId)

const buscarOpsM = async (params?: number): Promise<IFiltroOps> => ciclosService.buscarOps(params)

const buscarOpsVinculadasM = async (cicloId: string | number): Promise<IOpsVinculadas[]> => ciclosService.buscarOpsVinculadas(cicloId)

const cadastrarOpsM = async (cicloId: string | number): Promise<string> => ciclosService.cadastrarOps(cicloId)

const transicionarCiclo = async (transicao: TransicionarCiclo, cicloId?: string | number): Promise<string> =>
  ciclosService.transicionarCiclo(transicao, cicloId)

const buscarTotalCiclo = async (cicloId: string | number): Promise<TotalCiclo> => ciclosService.buscarTotalCiclo(cicloId)

const obterLancamentosDinstintos = async (cicloId: string): Promise<string[]> => ciclosService.obterLancamentosDinstintos(cicloId)

const verificarCiclosEmAbertoUltimasHoras = async (cicloId: string | number): Promise<void> => ciclosService.verificarCiclosEmAbertoUltimasHoras(cicloId)

export default {
  buscarParceirosComCiclos,
  iniciarCiclo,
  deletarCiclo,
  buscarCicloParceiro,
  buscarTabelaCiclo,
  buscarErrosProcessamento,
  buscarStatusCiclo,
  buscarLancamentosVariaveis,
  editarItemLancamentoVariaveis,
  verificarItensVariaveis,
  uploadArquivoCiclo,
  uploadArquivoTemp,
  buscarLancamentoNaoAssociado,
  tratarLancamentoNaoAssociado,
  concluirInternalizacao,
  buscarOpsM,
  buscarOpsVinculadasM,
  cadastrarOpsM,
  transicionarCiclo,
  buscarTotalCiclo,
  obterLancamentosDinstintos,
  verificarCiclosEmAbertoUltimasHoras
}
