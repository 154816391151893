import { Grid, Typography } from '@material-ui/core'
import { BackupOutlined } from '@material-ui/icons'
import { IOProps } from 'react-compose-io'
import ConfirmarDialog from '../../../../components/ConfirmarDialog/ConfirmarDialog'
import { GridTypography } from '../../../../components/GridTypography'
import { Tipografia } from '../../../../components/Tipografia'
import { CicloStatusGeral } from '../../../../types/ciclosApiType'
import { formateDate, validarTexto } from '../../../../utils/stringUtils'
import { CicloInformacaoIO } from './CicloInformacao.IO'
import { Botao } from '../../../../components/Botao'
import { TooltipComponent } from '../../../../components/Tooltip/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

export type CicloInformacaoProps = {
  cicloId: number | string | undefined
  parceiroId: number
  enableConfirmar: boolean
  opsSucesso: boolean
  statusCiclo: CicloStatusGeral
  disableCancelar: boolean
  updateStatus: () => void
  finalizarEtapa: () => void
  atualizarEtapa: () => void
  setEnableConfirmar: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CicloInformacaoView({
  _io,
  cicloId,
  enableConfirmar,
  disableCancelar,
  finalizarEtapa,
  atualizarEtapa
}: IOProps<CicloInformacaoIO, CicloInformacaoProps>) {
  const { Container, Input, UpdateButton, CancelButton } = _io.styles

  return (
    <>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xl md sm xs>
            <Tipografia variant="h6">{`Ciclo ${validarTexto(cicloId?.toLocaleString('pt-BR'), '-')}`}</Tipografia>
          </Grid>
          {!_io.cicloEfetivado && (
            <Grid item xl={5} lg={6} md={5} sm={12} xs={12} container justifyContent="flex-end">
              <CancelButton>
                {disableCancelar && <TooltipComponent texto="Processo em andamento." Icone={HelpOutlineIcon} />}
                <Botao
                  estilo={_io.habilitarAtualizar ? 'text' : 'outlined'}
                  cor="primaria"
                  textoBotao="Cancelar"
                  onClick={() => _io.setModalCancelamentoAberto(true)}
                  margin="0 23px 0 0"
                  disabled={disableCancelar}
                />
              </CancelButton>
              {_io.habilitarAtualizar && <Botao onClick={atualizarEtapa} textoBotao="Atualizar" estilo="outlined" cor="primaria" margin="0 24px 0 0" />}
              {!_io.uploadButton ? (
                <Botao textoBotao="Confirmar" disabled={!enableConfirmar} onClick={finalizarEtapa} cor="primaria" estilo="contained" />
              ) : (
                <>
                  <>
                    <Input accept={_io.inputFileOptions} id="contained-button-file" type="file" onChange={_io.arquivoSelecionado} />
                    <label htmlFor="contained-button-file">
                      <UpdateButton startIcon={<BackupOutlined />} textoBotao="Upload planilha" estilo="contained" cor="primaria" />
                    </label>
                  </>
                </>
              )}
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={2} justifyContent="flex-start">
          <GridTypography titulo={'Código do Parceiro'} valor={_io.parceiro?.parceiroId ?? ' - '} />
          <GridTypography titulo={'Razão Social'} valor={_io.parceiro?.razaoSocial ?? ' - '} />
          <GridTypography titulo={'Data do Ciclo'} valor={formateDate(_io.cicloDados?.dataCadastro)} />
        </Grid>
        <ConfirmarDialog
          open={_io.modalCancelamentoAberto}
          setOpen={_io.setModalCancelamentoAberto}
          handleConfirm={_io.cancelarCiclo}
          confirmarButtonLabel={'Sim, tenho!'}
          cancelarButtonLabel={'Não'}
          dialogTitle={'Confirmar exclusão do ciclo'}
        >
          <Typography variant="body1">Você tem certeza que deseja excluir o ciclo? Esta ação é IRREVERSÍVEL!</Typography>
        </ConfirmarDialog>
      </Container>
    </>
  )
}
