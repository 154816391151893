import { ExportIcon } from '../../assets/icons/ExportIcon'
import { handleExportarArquivo } from '../../utils/uploadFileUtils'
import { BotaoIconeSvg } from '../BotaoIconeSvg'

export type BotaoIconeExportarProps = {
  url: string
  disabled?: boolean
  corIcone?: string
}

export function BotaoIconeExportar({ url, corIcone, disabled }: BotaoIconeExportarProps): JSX.Element {
  return (
    <BotaoIconeSvg
      disabled={disabled}
      icone={<ExportIcon color={corIcone} disabled={disabled} width={24} height={24} />}
      onClick={async () => await handleExportarArquivo(url)}
    />
  )
}
